import React from 'react';
import '../styles/Footer.css';
import { AiOutlineGithub } from 'react-icons/ai';
import { BsLinkedin, BsStackOverflow } from 'react-icons/bs'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className='footerLogo'>
          <h3>TECH SQUARE!</h3>
          <p> Driving your business’s success with innovative cloud solutions and software development. Experience how Simple it can be with our flexibility, expertise, and scalability. </p>
        </div>
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>Email: contact@techsquare.ltd</p>
          <p>Phone: +923009159783</p>
        </div>
        <div className="footer-social">
          <h3>Follow Us</h3>

          <Link className='social-icons' to="https://github.com/Abskhan2001" target='_blank' ><AiOutlineGithub /></Link>
          <Link className='social-icons' to="https://www.linkedin.com/in/abbas-khan-0b814a219" target='_blank' ><BsLinkedin /></Link>
          <Link className='social-icons' to="https://github.com/Abskhan2001" target='_blank' ><BsStackOverflow /></Link>
          <div className="social-icons">
            <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
            <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; {new Date().getFullYear()} TechSquare. All rights reserved.</p>
      </div>
     
    </footer>
  );
}

export default Footer;
