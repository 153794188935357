import React from 'react';
import '../styles/HeroSection.css';
import { Link } from 'react-router-dom';
import img from '../assets/web_clutch.svg';
import img2 from '../assets/gartner_peer_insights-1.png';
import img3 from '../assets/nucc-1.svg';
import img4 from '../assets/logo-goodfirms-1.svg';
import img5 from '../assets/photo_2023-09-13-233016.jpeg';
import img6 from '../assets/HC_1.png';

function HeroSection() {
  return (
    <>
      <section className="hero-section">
        <div className="hero-content">
          <h1>
            Software Development <br />
            Company that Delivers <br />
            Deep Tech Solutions
          </h1>
          <p>
            We provide companies with senior tech talent and product <br />
            development expertise to build world-class software.
          </p>
          <p>Our core services:</p>
          <Link to="/service">
            <button className="cta-button">Get Started</button>
          </Link>
        </div>
        <div className="new-box">
          <div className="image-container">
            <img src={img} alt="Web Clutch" />
            <img src={img2} alt="Gartner Peer Insights" />
            <img src={img3} alt="NUCC" />
            <img src={img4} alt="GoodFirms" />
            <img src={img5} alt="Photo" />
            <img src={img6} alt="HC" />
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroSection;
