import React from 'react';
import '../styles/Portfoliosection.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import fproject from '../assets/OWINFY1.png'
import sproject from '../assets/Group.png'
import thproject from '../assets/Group18.png'
import Fouproject from '../assets/aca.png'
import Fivproject from '../assets/fivp.png'
import sixproject from '../assets/sixp.png'
import sevproject from '../assets/sevp.png'
const portfoliosection = () => {
  return (
    <section className="success-cases">
      <div className="wrapper">
        <h2 className="block-heading">Success cases</h2>

        {/* Flygo */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">Flygo</div>
              <div className="subheading">
              We're dedicated to making your travel dreams a reality with expertly curated itineraries, local insights and seamless booking options. Explore the world with confidence and ease, thanks to Flygo.
              </div>
              <div className="description">
                Travel
                <div className="address">Norway</div>
              </div>
              <a className="link" href="https://www.flygo.org/" target='blank'>
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
                    src={fproject}
                    alt="Norwegian case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/norwegian/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/* iManage School  Case */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">iManage School</div>
              <div className="subheading">A streamline administrative tasks and enhance academic efficiency with our comprehensive school management software, designed for seamless communication, efficient resource allocation and holistic student information management.</div>
              <div className="description">
              Management
                <div className="address">UK</div>
              </div>
              <a className="link" href="https://relevant.software/cases/firsthomecoach/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
                    src={sproject}
                    alt="FirstHomeCoach case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/firsthomecoach/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/*Avokado  Case */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">Avokado</div>
              <div className="subheading">Fresh Veg Fruit is a comprehensive online market-place dedicated to delivering farm-fresh vegetables and fruits right to your doorstep.Accessible via a web platform as well as Android and iOS applications. This e-market ensures customers to enjoy a hassle-free and healthy shopping experience.</div>
              <div className="description">
                Construction
                <div className="address">Norway</div>
              </div>
              <a className="link" href="https://relevant.software/cases/svenn/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
                    src={thproject}
                    alt="Svenn case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/svenn/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/* ACA Follow-Up App */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">ACA Follow-Up App</div>
              <div className="subheading">The ACA Follow Up App is a specialized application designed to streamline & monitor student behavior within a school environment tailored for educational institutions. This app allows for efficient tracking and management of student conduct, ensuring a conducive learning atmosphere.</div>
              <div className="description">
                Edtech
                <div className="address">UK</div>
              </div>
              <a className="link" href="https://apps.apple.com/pk/app/aca-mobile-app/id6450184778" target='blank'>
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
                    src={Fouproject}
                    alt="My Theory Test case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/my-theory-test/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Airthings Case */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">GIB Capital</div>
              <div className="subheading">There has been developed a new design of GIB Capital. The main activity of GIB Capital is providing a full range of financial services including the following activities: Dealing as principal, Agent, Underwriter, Arranging, Advising, Managing investment funds, Client’s portfolios and Custody.</div>
              <div className="description">
                IoT
                <div className="address">Norway</div>
              </div>
              <a className="link" href="https://www.gibcapital.com/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
                    src={Fivproject}
                    alt="Airthings case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/airthings/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Kaizo Case */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">Divano</div>
              <div className="subheading">Divano is your go-to destination for chic and comfortable furniture. Explore curated collections, transform your space effortlessly and indulge in a seamless E-commerce experience.</div>
              <div className="description">
                Management
                <div className="address">The Netherlands</div>
              </div>
              <a className="link" href="https://relevant.software/cases/kaizo/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
                    src={sixproject}
                    alt="Kaizo case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/kaizo/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>

        {/* FISK Case */}
        <div className="success-item">
          <div className="row between-xs">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="heading">Jetour Motors Services Mobile App</div>
              <div className="subheading">Jetour Motors Services Mobile App is designed for the customer to get the advance booking for the services and maintenance form the service center. Sometime customer can track their maintenance record to avoid delays in maintenance.</div>
              <div className="description">
                Fintech
                <div className="address">United States</div>
              </div>
              <a className="link" href="https://relevant.software/cases/fisk/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 end-sm">
              <div className="success-item-img">
                <div className="video-bg">
                  <img
                    width="768"
                    height="453"
 src={sevproject}
                    alt="FISK case"
                  />
                </div>
              </div>
              <a className="link mob-link-show" href="https://relevant.software/cases/fisk/">
                View case <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="browse-more-wrapper">
      <a className="all-link __animated-link" href="https://relevant.software/cases/">
        Browse more
        <i className="fas fa-caret-right"></i>
      </a>
    </div>
      </div>
    </section>
  );
};

export default portfoliosection;
