import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import HeroSection from '../../components/HeroSection'
import  Testimonials from '../../components/Testimonials'
import  Portfoliosection from '../../components/Portfoliosection'
import OurClients from '../../components/OurClients'
import Contactus from '../../components/Contactus'
import Footer from '../../components/Footer'
const HomePage = () => {
  return (
   <>
   <NavigationBar/>
   <HeroSection/>
   {/* <Testimonials/> */}
   <Portfoliosection/>
   <OurClients/>
   {/* <Contactus/> */}
   <Footer/>
   </>
  )
}

export default HomePage