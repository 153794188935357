import React, { useState } from 'react';
import '../styles/Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faCode, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Testimonials() {
  const [isVideoPlaying, setIsVideoPlaying] = useState([false, false]);

  const services = [
    {
      videoUrl: 'https://www.youtube.com/embed/LOS5WB75gkY?controls=0&modestbranding=1&rel=0&showinfo=0&autoplay=1&iv_load_policy=3',
    },
    {
      videoUrl: 'https://www.youtube.com/embed/LOS5WB75gkY?controls=0&modestbranding=1&rel=0&showinfo=0&autoplay=1&iv_load_policy=3', // Use different video URL for second card
    },
    {
      title: 'E-Commerce Solutions',
      description: 'We needed skilled staff and no downtime, which is what they’ve delivered. Relevant Software’s flexibility with new ideas, reliability, and transparency add tremendous value to the collaboration, which is foreseen to continue.',
      icon: faShoppingCart,
      imageUrl: 'https://plus.unsplash.com/premium_photo-1674854858248-8987c02e74cf?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8bWFufGVufDB8fDB8fHww', // Add the image URL here for the third card
    },
    {
      title: 'Crafting Web Excellence',
      description: "They stayed within our budget and scope, and their deliverables met our expectations. Quality results to-date make re-engagement for further development likely. The project’s thorough discovery phase benefited Relevant Software’s successful execution.",
      icon: faCode,
      imageUrl: 'https://plus.unsplash.com/premium_photo-1674854858248-8987c02e74cf?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8bWFufGVufDB8fDB8fHww', // Add the image URL here for the fourth card
    },
  ];

  return (
    <section className="services-section">
      <h2>Client Testimonials</h2>
      <div className="services-list">
        {services.map((service, index) => (
          <div className="service" key={index}>
            {service.videoUrl ? ( 
              <iframe
                id={`video-iframe-${index}`} 
                src={service.videoUrl}
                title={`${service.title} Video`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ 
                  pointerEvents: isVideoPlaying[index] ? 'auto' : 'none', 
                  height: '300px', 
                  width: '600px', 
                  borderRadius: '20px'
                }}
              />
            ) : (
              <>
                {service.imageUrl && (
                  <div className="service-header">
                    <img src={service.imageUrl} alt={service.title} className="service-image" />
                    <div className="service-text">
                      <span className="service-name">This the test title</span>
                      <span className="service-role">Founder of Biderator</span> 
                    </div>
                  </div>
                )}
                <p>{service.description}</p> 
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default  Testimonials;
