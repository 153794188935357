import React from 'react';
import '../styles/Ourclients.css'
import l1 from '../assets/ourclients/36000-saudi_arabian_gp_logo-.png'
import l2 from '../assets/ourclients/Avokado.png'
import l3 from '../assets/ourclients/Group 272.png'
import l4 from '../assets/ourclients/Group.png'
import l5 from '../assets/ourclients/Namariq.png'
import l6 from '../assets/ourclients/Picture1.png'
import l7 from '../assets/ourclients/Picture14.png'
import l8 from '../assets/ourclients/Picture15.png'
import l9 from '../assets/ourclients/Picture22.png'
import l10 from '../assets/ourclients/Picture5.png'
import l11 from '../assets/ourclients/Swec.png'
import l12 from '../assets/ourclients/Zappta-Official-Logo-Vector-(1).png'
import l13 from '../assets/ourclients/arabian online store.png'
import l14 from '../assets/ourclients/buy and do.png'
import l15 from '../assets/ourclients/children world school.png'
import l16 from '../assets/ourclients/coral international school 1.png'
import l17 from '../assets/ourclients/crowdless.png'
import l18 from '../assets/ourclients/elysian.png'
import l19 from '../assets/ourclients/flyt.png'
import l20 from '../assets/ourclients/gardenia.png'
import l21 from '../assets/ourclients/ilogistics.png'
import l22 from '../assets/ourclients/imanage school.png'
import l23 from '../assets/ourclients/isdb.png'
import l24 from '../assets/ourclients/jetour.png'
import l25 from '../assets/ourclients/logo-colored-removebg-preview.png'
import l26 from '../assets/ourclients/site-logo.png'
import l27 from '../assets/ourclients/virtual clinic.png'
import p1 from '../assets/onep.webp'
import p2 from '../assets/2p.webp'
function OurClients() {

  const clients = [
    [
      l1,l2,l26,l4,l27,l6,
    ],
    [
        l7,l8,l9,l10,l12,l3,
    ],
    [
        l13,l14,l15,l16,l17,l23,
    ],
    [
        l19,l20,l21,l22,l24,l15,
    ],
    // [
    //     l25,l18,l11,
    // ],
  ];
   const differences = [
    {
      heading: 'Rigorous selection of experts',
      description:
        'We have a thorough recruitment process to check engineers\' background, tech skills, English level, and soft skills to provide you with a best-fit candidate for a final interview.',
    },
    {
      heading: 'Maturity in product development',
      description:
        'We’ve built and launched more than 200 products in 15 countries across the globe.',
    },
    {
      heading: 'Just like in-house development',
      description:
        'Choose your preferred methodology and tools for syncing with and managing your team.',
    },
  ];


  const teamMembers = [
    {
      name: 'Yasir Zubair',
      title: 'Founder & CEO',
      imgSrc: p1,
      alt: 'Petro Diakiv',
    },
    {
      name: 'Jasim Akhtar',
      title: 'CO-Founder',
      imgSrc: p2,
      alt: 'Lana Katasonova',
    },
  ];
  const expectations = [
    'Our BizDev managers will reach out within 24 hours to dive deeper into your requirements and discuss your project idea.',
    'If needed, we will sign an NDA to ensure your project details are fully protected.',
    'Our skilled software development experts will provide tailored advice and suggest the best development path.',
  ];


  return (
    <>
     <div className="clients-slider-container">
      <h2 className="clients-heading">Our Clients</h2>
     
      <div
        className="our-clients-slider-item slick-slide slick-current slick-active"
        style={{ width: '1070px' }}
        tabIndex="0"
        role="tabpanel"
        aria-describedby="slick-slide-control20"
        data-slick-index="0"
        aria-hidden="false"
      >
        {clients.map((row, rowIndex) => (
          <div className="slider-row" key={rowIndex}>
            {row.map((clientLogo, index) => (
              <div className="our-clients-item" key={index}>
                <img
                  src={clientLogo}
                  data-lazy-src={clientLogo}
                  data-ll-status="loaded"
                  className="entered lazyloaded"
                  alt={`Client logo ${index + 1}`}
                />
                <noscript>
                  <img src={clientLogo} alt={`Client logo ${index + 1}`} />
                </noscript>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
<section className="industry-box">
      <div className="wrapper">
        <h2 className="block-heading">Industry expertise</h2>

        <div className="industry-box-item Fintech ">
          <div className="front">

            <div className="row">
              <div className="description col-xs-12 col-md-7">
              <h4 className="heading">Fintech</h4>
                <ul className="list">
                  <li>Digital banking systems</li>
                  <li>Blockchain-based finance apps and solutions</li>
                  <li>Trading, investment, money transaction,</li>
                  <li>and crowdfunding platforms</li>
                </ul>
              </div>
            </div>
            {/* <a className="link __animated-link" href="https://relevant.software/fintech/">
              Learn more<i className="fas fa-chevron-right"></i>
            </a> */}
          </div>
        </div>

        <div className="industry-box-item Retail">
          <div className="front">
            <div className="row">
              <div className="description col-xs-12 col-md-7">
              <h4 className="heading">Retail</h4>
                <ul className="list">
                  <li>POS and e-commerce solutions</li>
                  <li>ERP, accounting, and warehouse management systems</li>
                  <li>Digital marketing and loyalty platforms</li>
                  <li>Sales and supply chain management apps</li>
                </ul>
              </div>
            </div>
            {/* <a className="link __animated-link" href="https://relevant.software/retail/">
              Learn more<i className="fas fa-chevron-right"></i>
            </a> */}
          </div>
        </div>

        <div className="industry-box-item Real-Estate">
          <div className="front">
            <div className="row">
              <div className="description col-xs-12 col-md-7">
              <h4 className="heading">Real Estate</h4>
                <ul className="list">
                  <li>Search and recommendation engines</li>
                  <li>Real estate valuation software</li>
                  <li>Portals for owners and tenants</li>
                  <li>Apps for real estate agents management</li>
                </ul>
              </div>
            </div>
            {/* <a className="link __animated-link" href="https://relevant.software/real-estate/">
              Learn more<i className="fas fa-chevron-right"></i>
            </a> */}
          </div>
        </div>

        <div className="industry-box-item Travel">
          <div className="front">
            <div className="row">
              <div className="description col-xs-12 col-md-7">
              <h4 className="heading">Travel</h4>
                <ul className="list">
                  <li>Online booking platforms and marketplaces</li>
                  <li>Labor management systems</li>
                  <li>Car rental and carsharing apps</li>
                  <li>Cloud-based scheduling and back-office solutions</li>
                </ul>
              </div>
            </div>
            {/* <a className="link __animated-link" href="https://relevant.software/travel/">
              Learn more<i className="fas fa-chevron-right"></i>
            </a> */}
          </div>
        </div>

        <div className="industry-box-item Construction">
          <div className="front">
          
            <div className="row">
              <div className="description col-xs-12 col-md-7">
              <h4 className="heading">Construction</h4>
                <ul className="list">
                  <li>Construction management software</li>
                  <li>Custom blueprinting and modeling software</li>
                  <li>Microservices development</li>
                  <li>Smart home management apps</li>
                </ul>
              </div>
            </div>
            {/* <a className="link __animated-link" href="https://relevant.software/construction/">
              Learn more<i className="fas fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
      </div>
    </section>


    <section className="service-where-we-differ relevant">
      <div className="wrapper">
        <h2 className="block-heading relevant">What makes us Relevant</h2>
        {differences.map((item, index) => (
          <div className="service-where-we-differ-item" key={index}>
            <div className="heading">{item.heading}</div>
            <div className="description">{item.description}</div>
          </div>
        ))}
      </div>
    </section>

    <div className="call-form-container">
  <h2>Let's build something exceptional together. Your journey starts with just one click!</h2>

  <div className="call-form-team">
    {teamMembers.map((member, index) => (
      <div className="call-form-team-member" key={index}>
        <img
          src={member.imgSrc}
          alt={member.alt}
          className="entered lazyloaded"
        />
        <h3>{member.name}</h3>
        <p>{member.title}</p>
      </div>
    ))}
  </div>

  <div className="what-to-expect">
    <h3>What to Expect Next?</h3>
    <ol>
      {expectations.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ol>
  </div>

  <div className="call-form-cta">
    <a
      href="https://calendly.com/relevant-software/intro-call?month=2024-09"
      target="_blank"
      rel="nofollow"
      className="call-form-btn"
    >
      Book a Call
    </a>
  </div>
</div>
    </>
  );
}

export default OurClients;
