import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Hamburger and close icons
import { Link } from 'react-router-dom';
import '../styles/NavigationBar.css';
import logo from '../assets/logo.png';

function NavigationBar() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false); // Dropdown state

  const toggleMenu = () => {
    setMenuVisible(prevState => !prevState); // Toggle the menu visibility
  };

  const toggleDropdown = () => {
    setDropdownVisible(prevState => !prevState); // Toggle dropdown visibility
  };

  return (
    <nav className="navigation-bar">
      <div className="logo">
        <img src={logo} id='logoimg' alt="logo" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {menuVisible ? <FaTimes /> : <FaBars />} {/* Switch between icons */}
      </div>
      <ul className={`nav-links ${menuVisible ? 'active' : ''}`}>
        <li><Link to="/" className="nav-link">Home</Link></li>
        
        {/* Services dropdown */}
        <li className="nav-link services-link" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <span>Services</span>
          {dropdownVisible && (
            <ul className="dropdown">
              <li><Link to="/service/web-development" className="dropdown-link">Web Development</Link></li>
              <li><Link to="/service/mobile-development" className="dropdown-link">Mobile Development</Link></li>
              <li><Link to="/service/seo" className="dropdown-link">SEO</Link></li>
            </ul>
          )}
        </li>

        <li><Link to="/Pricing" className="nav-link">Pricing</Link></li>
        <li><Link to="/portfolio" className="nav-link">Portfolio</Link></li>
        <li><Link to="/about" className="nav-link">About Us</Link></li>
        <li><Link to="/testimonials" className="nav-link">Testimonials</Link></li>
        <li><Link to="/contact" className="nav-link">Contact</Link></li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
